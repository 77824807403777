<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Qrscaner from '@/components/QRcode/scanQRcode';
import SerialCode from '@/components/QRcode/serialCode';
import errorHandler from './../../../helpers/errorHandler';
import Swal from 'sweetalert2';
import { required, helpers } from "vuelidate/lib/validators";
let phoneNumberRegex = helpers.regex('phoneNumberRegex',/^201[0-2,5]{1}[0-9]{8}$/)

/**
 * Starter component
 */
export default {
  page: {
    title: "Link Student",
  },
  head() {
    return {
      title: `Link Student`,
    };
  },
  data() {
    return {
      title: "Link Students",
      items: [
        {
          text: "Attendance",
          href: "/",
        },
        {
          text: "Link Student",
          href: "/link",
        },
        {
          text: "Qr scaner",
          active: true,
        },
      ],
      qrCode: "",
      qrShow: true,
      phoneForm: false,
      phone: null,
      submitted: false,
      method: 1,
    };
  },
  components: {
    Layout,
    PageHeader,
    Qrscaner,
    SerialCode,
  },
  methods:{
    switcher(){
      if(this.method == 1){
        this.method = 2
      }else{
        this.method = 1
      }
    },
    cancleForm(){
      this.qrCode= "";
      this.qrShow= true;
      this.phoneForm= false;
      this.phone= null;
      this.submitted= false;
    },
    getQRcode(value){
        this.qrCode = value;
        this.qrShow = false;
        this.phoneForm = true;
    },
    tryToLink() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.dispatch("session/linkStudentQrcode", {
            qrcode: this.qrCode,
            phone: this.phone,
        }).then( token =>{
            Swal.fire({
              icon: 'success',
              title: "Success",
              text: "Student linked",
              confirmButtonClass: "btn btn-confirm mt-2",
              });
              this.cancleForm();
            }).catch((error) => {
            errorHandler.methods.error(error);
        });
      }
    },
  },
  validations: {
    phone: {
      required,
      phoneNumberRegex
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="this.qrShow">
      <div class="row justify-content-center" >
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4">
              <div class="row justify-content-center" v-if="qrShow">
                <div class="col-md-6" v-if="this.qrShow && this.method == 1">
                  <Qrscaner v-if="this.qrShow && this.method == 1" @qrtext="getQRcode"/>
                </div>
                <div class="col-md-6" v-if="this.qrShow && this.method == 2">
                  <SerialCode v-if="this.qrShow && this.method == 2" @qrtext="getQRcode"/>
                </div>
              </div>
              <b-row v-if="this.qrShow">
                <b-col class="col-md-2 offset-md-10 offset-0">
                  <b-button variant="outline-warning" pill class="m-1" @click="switcher">
                  <i class="fas fa-sync mr-1"></i>
                    Swtich 
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-if="phoneForm">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                  <span class="logo-lg">
                    <!-- <img
                      :src="require(`../../../../../backend/database/qr_codes/${this.qrCode}.png`)"
                      alt=""
                      height="220"
                    /> -->
                  </span>
              </div>
            </div>

            <form action="#" @submit.prevent="tryToLink">
              <div class="form-group mb-3">
                <label for="Phoneid">Phone</label>
                <input
                  class="form-control"
                  v-model="phone"
                  type="text"
                  id="phoneid"
                  placeholder="Enter student phone number"
                  :class="{ 'is-invalid': submitted && $v.phone.$error }"
                />
                <div
                  v-if="submitted && $v.phone.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.phone.required">Phone is required.</span>
                </div>
                <div
                  v-if="submitted && $v.phone.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.phone.phoneNumberRegex">Please enter a phone.</span>
                </div>
              </div>
              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">
                  Link
                </button>
              </div>
              <div class="form-group mb-0 text-center mt-1">
                <button class="btn btn-danger btn-block" @click="cancleForm">
                  Cancle
                </button>
              </div>
            </form>

           
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
