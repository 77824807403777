<script>
import errorHandler from '../../helpers/errorHandler';
import { required, minLength } from "vuelidate/lib/validators";

/**
 * Starter component
 */
export default {
  data() {
    return {
      
      qrCode: "",
      phone: null,
      submitted: false,
      code: null,

    };
  },
  methods:{
    tryToLink() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        // TODO :: add api here 
        // this.$store.dispatch("session/phoneValidator", {phone:this.phone}).then( token =>{
        //     this.$emit('qrtext',token.qr_code)
        //     }).catch((error) => {
        //     errorHandler.methods.error(error);
        // });
      }
    },
  },
  validations: {
    code: {
      required,
      minLength: minLength(4)
    },
  },
};
</script>

<template>
  <form action="#" @submit.prevent="tryToLink">
    <div class="form-group mb-3">
      <label for="Phoneid">Serial code</label>
      <input
        class="form-control"
        v-model="code"
        type="text"
        id="phoneid"
        placeholder="Enter serial code"
        :class="{ 'is-invalid': submitted && $v.code.$error }"
      />
      <div
        v-if="submitted && $v.code.$error"
        class="invalid-feedback"
      >
        <span v-if="!$v.code.required">Field is required.</span>
      </div>
      <div
        v-if="submitted && $v.code.$error"
        class="invalid-feedback"
      >
        <span v-if="!$v.code.minLength">Min length is 4 digits.</span>
      </div>
    </div>
    <div class="form-group mb-0 text-center">
      <button class="btn btn-primary btn-block" type="submit">
        Validate
      </button>
    </div>
  </form>
</template>
